import React, { useCallback, useEffect, useState } from "react";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import api from "../../../../services/api";
import Banner from "../../../atoms/banner/Banner";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InfoPopups from "../../simulations/steps/help/InfoPopups";

const InfoPopupsScreen: React.FC = () => {
  const getHelp = useCallback(async () => {
    return await api.getHelpConfig();
  }, []);

  const { data: helpItems, inProgress, error } = useMemoRequest(getHelp);

  const [helpItemsData, setHelpItemsData] = useState<
    API.HelpConfigResponse[] | null
  >(null);

  useEffect(() => {
    if (helpItems) {
      setHelpItemsData(helpItems);
    }
  }, [helpItems]);

  const handleHelpUpdate = useCallback(
    async (helpId: string, data: Partial<API.HelpConfigResponse>) => {
      setHelpItemsData((prev) => {
        if (!prev) {
          return prev;
        }
        return prev.map((help) => {
          if (help.id === helpId) {
            return { ...help, ...data };
          }
          return help;
        });
      });
      await api.updateHelpConfig(helpId, data);
    },
    [],
  );

  return (
    <>
      <h3>Info Popups</h3>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {helpItemsData && (
        <VerticalGroup full wide>
          <InfoPopups
            onUpdateContent={handleHelpUpdate}
            helpItems={helpItemsData}
          />
        </VerticalGroup>
      )}
    </>
  );
};

export default InfoPopupsScreen;
